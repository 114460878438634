<template>
  <div class="result">
    <div class="title">您的房产估价为:</div>
    <div class="cal_nav">
      <div class="cal_changenav"
           :class="top == 'totalPrice' ? 'active_nav' : ''"
           @click="top = 'totalPrice'">
        房产总价
      </div>
      <div class="cal_changenav"
           :class="top == 'signerPrice' ? 'active_nav' : ''"
           @click="top = 'signerPrice'">
        房产单价
      </div>
    </div>
    <div class="result-button">
      <img class="light"
           draggable="false"
           src="../assets/light.png" />
      <div class="start-wrapper">
        <img class="start"
             @click="start"
             draggable="false"
             src="../assets/index-start.png" />
        <div class="result-text">
          <div v-show="top == 'totalPrice'">
            <div v-if="totalPrice">
              <div>{{ totalPrice }}</div>
              <div class="unit">元 / 套</div>
            </div>
            <div v-else>
              <div>{{ startPrice }}</div>
              <div>至</div>
              <div>{{ endPrice }}</div>
              <div style="margin-top: 5px;">元 / 套</div>
            </div>
          </div>
          <div v-show="top == 'signerPrice'">
            <div>{{ signerPrice }}</div>
            <div class="unit">元 / 平</div>
          </div>
        </div>
      </div>
      <div class="tofangdai"
           @click="$router.push('/fangdaidt')">
        我要申请贷款 >
      </div>
    </div>
    <div class="tips">注：以上价格仅供参考， 具体房屋估值以我行网点与您共同确认后的最终价值为准。房产估价未扣除出让金与法定优先受偿款。</div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "result",
  data () {
    return {
      top: "totalPrice",
      totalPrice: "",
      startPrice: "",
      endPrice: "",
      signerPrice: "",
    };
  },
  created () {
    this.totalPrice = this.$route.query.totalPrice;
    this.startPrice = this.$route.query.startPrice;
    this.endPrice = this.$route.query.endPrice;
    this.signerPrice = this.$route.query.signerPrice;
  },
  methods: {
    start () {
      this.$router.replace({
        name: "Detection",
      });
    },
  },
};
</script>
<style scoped lang="less">
.result {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../assets/bg.png');
  background-size: cover;
  overflow: hidden;

  .title {
    font-weight: bold;
    margin-top: 10%;
    font-size: 30px;
    text-align: center;
  }

  .cal_nav {
    width: 180px;
    height: 28px;
    margin-top: 20px;
    display: flex;
    font-size: 12px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;

    .cal_changenav {
      width: 50%;
      color: #cf4233;
      text-align: center;
      line-height: 28px;
      border: 1px solid #fff;
      background-color: #fff;

      &:first-child {
        border-radius: 6px 0 0 6px;
        border-right: 0;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }

      &.active_nav {
        background-color: #cf4233;
        color: #fff;
      }
    }
  }

  .result-button {
    position: absolute;
    display: flex;
    justify-content: center;
    transform: translate(0, -50%);
    top: 45%;

    .start-wrapper {
      .start {
        max-width: 50%;
        max-height: 25vh;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .result-text {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        text-align: center;
        font-weight: bold;

        .unit {
          margin-top: 5px;
          position: relative;
          top: 10px;
        }
      }
    }

    .light {
      max-width: 90%;
      max-height: 80vh;
      animation: turn 5s linear infinite;
    }

    @keyframes turn {
      0% {
        -webkit-transform: rotate(0deg);
      }
      25% {
        -webkit-transform: rotate(90deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      75% {
        -webkit-transform: rotate(270deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
  .tofangdai {
    position: absolute;
    bottom: -20px;
    background-color: #cf4233;
    font-size: 14px;
    border-radius: 6px;
    border: 2px solid #fff;
    padding: 5px 10px;
  }
  .tips {
    position: absolute;
    bottom: 10px;
    font-size: 10px;
    width: 80%;
    text-align: center;
    margin-left: 10%;
  }
}
</style>
